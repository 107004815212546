@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: "Noto Sans KR", ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif;
}

html,
body,
#root,
.App {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

ul,
li {
  list-style: none;
}

*::placeholder {
  color: #d8d8d8;
  font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

h1 {
  font-size: 30pt;
}

h2 {
  font-size: 24pt;
}

h3 {
  font-size: 20pt;
}

h4 {
  font-size: 18pt;
}

h5 {
  font-size: 16pt;
}

h6 {
  font-size: 14pt;
}

ul.after-arrow-right li {
  position: relative;
  padding-right: 10px;
  margin-right: 10px;
}

ul.after-arrow-right li + li::after {
  position: absolute;
  content: ">";
  left: -15px;
  top: -1px;
}

span.required::before {
  content: "*";
  color: #eb2773;
  margin-right: 4px;
}

.accent {
  color: #eb2773;
}

.box-shadow {
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.08);
}

.keep-all {
  word-break: keep-all;
}

.break-word {
  word-break: break-word;
}

.break-all {
  word-break: break-all;
}

.react-datepicker-popper {
  z-index: 40;
}

.react-datepicker__day--selected {
  background-color: #fde9f1;
  color: #eb2773;
  font-weight: bold;
}

.react-datepicker__day--selected:hover {
  background-color: #fbd3e3;
}

.react-datepicker__day--today {
  background-color: transparent;
  background-color: #eb2773;
  color: white;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* 스크롤바 트랙 꾸미기 */
::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 5px;
}

/* 스크롤바 손잡이 꾸미기 */
::-webkit-scrollbar-thumb {
  background: #dcdcdc;
  border-radius: 5px;
}

/* 스크롤바 손잡이 호버 시 색 */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.hidden-scroll::-webkit-scrollbar {
  height: 0px;
}

.horizontal-scroll::-webkit-scrollbar {
  height: 8px;
}

/* .horizontal-scroll::-webkit-scrollbar-track {
  background-color: #f991c3;
}

.horizontal-scroll::-webkit-scrollbar-thumb {
  background-color: #f52388;
} */

@keyframes Bling {
  100% {
    opacity: 1;
  }
  0% {
    opacity: 0;
  }
}

@keyframes Snake {
  100% {
    left: 105%;
  }
  0% {
    left: -105%;
  }
}

.animate-snake {
  animation-name: Snake;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

/* 2줄 일립시스 */
.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ql-container {
  font-size: 15px;
  border: none !important;
}
